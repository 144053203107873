import React from 'react'
import PaintsSection from '../components/PaintsSection'

const Paints = () => {
  return (
    <>
    <PaintsSection />
    </>
  )
}

export default Paints