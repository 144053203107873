export const projects = [
  {
    _id: 1,
    category: "completed",
    image: "/Images/projects/completed-2.jpeg",
  },
  {
    _id: 4,
    category: "completed",
    image: "/Images/projects/completed-6.jpeg",
  },
  {
    _id: 5,
    category: "ongoing",
    image: "/Images/projects/ongoing-1.jpeg",
  },
  {
    _id: 6,
    category: "ongoing",
    image: "/Images/projects/ongoing-2.jpeg",
  },
  {
    _id: 7,
    category: "ongoing",
    image: "/Images/projects/ongoing-3.jpeg",
  },
  {
    _id: 8,
    category: "ongoing",
    image: "/Images/projects/ongoing-4.jpeg",
  },
];

export const completedProjects = [
  {
    _id: 0,
    before: "/Images/projects/project-1-b4.jpeg",
    after: "/Images/projects/project-1-af.jpeg",
  },
  {
    _id: 1,
    before: "/Images/projects/project-2-b4.jpeg",
    after: "/Images/projects/project-2-af.jpeg",
  },
  {
    _id: 2,
    before: "/Images/projects/project-5-b4.jpeg",
    after: "/Images/projects/project-5-af.jpeg",
  },
];

export const CSRs = [
  {
    _id: 0,
    title: "Dandora Police Facelift",
    image: "/Images/csr/dandora-police/dandora-2.jpeg",
  },
  {
    _id: 1,
    title: "Dandora Hip Hop City",
    image: "/Images/csr/dandora-hiphop-city/dandora-hip-hop-1.jpeg",
  },
];
