export const partners = [
  {
    _id: 0,
    img: "/Images/partners/partner-1.png",
  },
  {
    _id: 1,
    img: "/Images/partners/partner-2.png",
  },
  {
    _id: 2,
    img: "/Images/partners/partner-3.PNG",
  },
  {
    _id: 3,
    img: "/Images/partners/partner-4.png",
  },
  {
    _id: 4,
    img: "/Images/partners/partner-5.png",
  },
  {
    _id: 5,
    img: "/Images/partners/partner-6.png",
  },
  {
    _id: 6,
    img: "/Images/partners/partner-7.png",
  },
  {
    _id: 7,
    img: "/Images/partners/partner-8.png",
  },
];
