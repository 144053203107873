export const banners = [
  {
    id: 0,
    img: "/banners/banner-1.png",
  },
  {
    id: 1,
    img: "/banners/banner-2.png",
  },
  {
    id: 2,
    img: "/banners/banner-3.png",
  },
  {
    id: 3,
    img: "/banners/banner-4.png",
  },
  {
    id: 4,
    img: "/banners/banner-5.jpeg",
  },
];
