import React from 'react'
import ProjectsSection from '../components/projects/ProjectsSection'

const Projects = () => {
  return (
    <>
    <ProjectsSection />
    </>
  )
}

export default Projects